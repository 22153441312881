.phoneListName {
  font-size: 70%;
  @include md {
    font-size: 90%;
  }
  @include lg {
    font-size: 110%;
  }
}

.phoneListDescription {
  font-size: 50%;
  @include md {
    font-size: 70%;
  }
  @include lg {
    font-size: 90%;
  }
}

.phoneItemContainer {
  margin-bottom: $column-gap * 2;
}

.phoneListItem {
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 0 15px #0f5d99;
}

.phoneListItemIcon1 {
  font-size: 1em;
  @include md {
    font-size: 2em;
  }
  @include lg {
    font-size: 3em;
  }
}

.phoneListLimit {
  font-size: 60%;
  color: white;
  margin: 0;
  padding: 0;
}

.phoneListLimit .column {
  margin: 0;
  padding: 0;
}

.phoneListItemIcon2 {
  font-size: .7em;
  @include md {
    font-size: 1em;
  }
  @include lg {
    font-size: 2em;
  }
}

.reminderListPhone {
  font-size: 70%;
  @include md {
    font-size: 90%;
  }
  @include lg {
    font-size: 110%;
  }
}

.reminderListDescription {
  font-size: 50%;
  @include md {
    font-size: 70%;
  }
  @include lg {
    font-size: 90%;
  }
}

.reminderListText {
  font-weight: bold;
  font-size: 50%;
  @include md {
    font-size: 70%;
  }
  @include lg {
    font-size: 90%;
  }
}

.accordion-header {
  border-radius: 1px !important;
}
.accordion-header > button {
  background-color: black;
}

.reminderItemSection {
  background-color: #133652 !important;
}

.reminderListSection {
  cursor: pointer !important;
}

.reminderItemContainer {
  margin-top: $column-gap * 2;
  margin-bottom: $column-gap * 2;
}

.reminderItem {
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 0 15px #0f5d99;
}

.reminderListItemIcon1 {
  font-size: 1em;
  @include md {
    font-size: 2em;
  }
  @include lg {
    font-size: 3em;
  }
}

.reminderListItemIcon2 {
  font-size: .7em;
  @include md {
    font-size: 1em;
  }
  @include lg {
    font-size: 2em;
  }
}

@import "screens.scss";

.phoneUpdateImage {
  max-width: 100%;
  height: auto;
}

.phoneUpdateImageContainer {
  width: 20vw;
  display: inline-flex;
  justify-content: center;
  @include lg {
    width: 25vw;
  }
}

.phoneAddressBook {
  width: 90vw;
  height: auto;

  @include md {
    width: 25vw;
  }
}
